<template>
  <v-card outlined>
    <v-card-title class="justify-center">
      Studio Login
    </v-card-title>
    <v-card-text
      v-if="resetMailSent"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="success"
      >
        <p>The password reset link was sent.</p>
        <p>Please click the link in the email to reset your password, then come back here to sign in.</p>
      </v-alert>
      <v-card-actions class="justify-center">
        <v-btn
          color="success"
          large
          @click="resetMailSent=false;forgotPassword=false;errorMessage=null"
        >
          Sign In With New Password
          <v-icon right>
            mdi-login
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <v-card-text
      v-else-if="sendingResetMail"
      class="d-flex flex-column justify-center align-center"
    >
      <v-progress-circular
        class="mb-5"
        indeterminate
      />
      Sending Password Reset Mail
    </v-card-text>
    <v-card-text
      v-else-if="errorMessage"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ errorMessage }}
      </v-alert>
    </v-card-text>
    <validation-observer
      v-else
      tag="form"
      @submit.prevent="signIn"
    >
      <v-card-text>
        <validation-provider
          v-slot="{ errors }"
          name="email"
          rules="email"
          tag="div"
          class="fire-input"
        >
          <v-text-field
            v-model="email"
            label="Email"
            type="email"
            outlined
            :hide-details="!errors.length"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          v-if="!forgotPassword"
          v-slot="{ errors }"
          name="password"
          tag="div"
          class="fire-input"
        >
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            outlined
            :hide-details="!errors.length"
            :error-messages="errors"
          />
        </validation-provider>
        <v-card-actions v-if="!forgotPassword" class="justify-space-around">
          <v-btn
            text
            large
            color="lightgrey"
            :disabled="signingIn"
            @click="forgotPassword=true"
          >
            Forgot Password
          </v-btn>
          <v-btn
            type="submit"
            color="secondary"
            large
            class="px-5"
            :disabled="signingIn"
            :loading="signingIn"
          >
            Sign In
            <v-icon right>
              mdi-login
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="justify-center">
          <v-btn
            text
            large
            color="lightgrey"
            :disabled="sendingResetMail"
            :loading="sendingResetMail"
            @click="resetPassword"
          >
            Send Reset Link
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="google-sign-in"
          @click="google"
        >
          <v-icon left>
            mdi-google
          </v-icon>
          Sign in with Google
        </v-btn>
      </v-card-actions>
    </validation-observer>
    <v-card-text class="text-center">
      By signing in you agree to our <a href="https://fireful.io/tos" target="_blank">Terms</a> & <a href="https://fireful.io/privacy" target="_blank">Privacy Policy</a>.
    </v-card-text>
  </v-card>
</template>

<script>
import { GoogleAuthProvider, auth } from '@/plugins/firebase'
export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    signingIn: false,
    forgotPassword: false,
    sendingResetMail: false,
    resetMailSent: false,
    errorMessage: null
  }),
  watch: {
    email() {
      this.errorMessage = null
    },
    password() {
      this.errorMessage = null
    }
  },
  methods: {
    async signIn() {
      this.signingIn = true
      try {
        await auth.signInWithEmailAndPassword(this.email, this.password)
        this.$router.push({ name: 'index' })
      } catch (error) {
        this.errorMessage = error.message
      }
      this.signingIn = false
    },
    async resetPassword() {
      this.sendingResetMail = true
      try {
        await auth.sendPasswordResetEmail(this.email)
        this.resetMailSent = true
      } catch (error) {
        this.errorMessage = error.message
      }
      this.sendingResetMail = false
    },
    async google() {
      try {
        const provider = new GoogleAuthProvider()
        await auth.signInWithPopup(provider)
        this.$router.push('/')
      } catch (error) {
        this.errorMessage = error.message
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .google-sign-in
    background: white
    color: #444
    border-radius: 5px
    border: thin solid #888
    box-shadow: 1px 1px 1px grey
    white-space: nowrap
</style>
